import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isValidNumber } from 'libphonenumber-js';

// Component Imports
import InputSelect from '../../../../Forms/InputSelect';
import InputField from '../../../../Forms/InputField';
import DateSelector from '../../../../Forms/DateSelector';

const PersonalDetails = (props) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCAPersonalInfo);

  return (
    <>
      <div className="b-form-group row row-nomargin">
        <div className="col-sm-6 col-no-margins">
          <InputSelect
            labelText="Select title"
            id="customer-title"
            onChange={(event) => dispatch({ type: 'SET_TITLE', payload: event.target.value })}
            ref={props.register({ required: true })}
            hasError={!!props.validationErrors['customer-title']}
            value={query.title}
          >
            <InputSelect.Option value="">Select title</InputSelect.Option>
            <InputSelect.Option value="Mr.">Mr</InputSelect.Option>
            <InputSelect.Option value="Mrs.">Mrs</InputSelect.Option>
            <InputSelect.Option value="Ms.">Ms</InputSelect.Option>
            <InputSelect.Option value="Miss.">Miss</InputSelect.Option>
            <InputSelect.Option value="Dr.">Dr</InputSelect.Option>
            <InputSelect.Option value="Rev.">Rev</InputSelect.Option>
          </InputSelect>
        </div>

        <InputField
          className="col-sm-6 col-no-right-padding"
          labelText="First name"
          id="customer-first-name"
          placeholder="First name"
          onChange={(event) => dispatch({ type: 'SET_FIRST_NAME', payload: event.target.value })}
          ref={props.register({ required: true, pattern: /^[A-Za-z\s-]+$/i })}
          hasError={!!props.validationErrors['customer-first-name']}
          value={query.firstName}
        />
      </div>

      <InputField
        labelText="Surname"
        id="customer-surname"
        placeholder="Surname"
        onChange={(event) => dispatch({ type: 'SET_SURNAME', payload: event.target.value })}
        ref={props.register({ required: true, pattern: /^[A-Za-z\s-]+$/i })}
        hasError={!!props.validationErrors['customer-surname']}
        value={query.surname}
      />

      <DateSelector
        id="customer-dob"
        groupText="Date of birth"
        dispatchAction="SET_DATE_OF_BIRTH"
        required
        ref={props.register({ required: true })}
        hasError={props.validationErrors}
        value={query.dateOfBirth}
      />

      <InputField
        type="tel"
        labelText="Telephone"
        id="customer-telephone"
        placeholder="Telephone"
        onChange={(event) => dispatch({ type: 'SET_TELEPHONE', payload: event.target.value })}
        ref={props.register({
          required: true,
          validate: (value) => isValidNumber(value, 'GB'),
        })}
        hasError={!!props.validationErrors['customer-telephone']}
        value={query.telephone}
      />

      <InputField
        type="email"
        labelText="Email"
        id="customer-email"
        placeholder="Email"
        disabled={!!props.verifyQuery.email}
        onChange={(event) => dispatch({ type: 'SET_EMAIL', payload: event.target.value })}
        ref={props.register({
          required: true,
          pattern:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        })}
        hasError={!!props.validationErrors['customer-email']}
        value={props.verifyQuery.email || query.email}
      />

      <div className="b-form-group">
        <InputSelect
          labelText="Select Marital Status"
          id="customer-marital"
          onChange={(event) =>
            dispatch({
              type: 'SET_MARITAL_STATUS',
              payload: event.target.value,
            })
          }
          ref={props.register({ required: true })}
          hasError={!!props.validationErrors['customer-marital']}
          value={query.maritalStatus}
        >
          <InputSelect.Option value="">Select Marital Status</InputSelect.Option>
          <InputSelect.Option value="Married">Married</InputSelect.Option>
          <InputSelect.Option value="Single">Single</InputSelect.Option>
          <InputSelect.Option value="Living with partner">Living with partner</InputSelect.Option>
          <InputSelect.Option value="Divorced">Divorced</InputSelect.Option>
        </InputSelect>
      </div>

      <InputField
        type="number"
        min="0"
        max="99"
        labelText="Number of dependents"
        id="customer-dependents"
        placeholder="Number of dependents"
        onChange={(event) => {
          const value = event.target.value.slice(0, 2);
          dispatch({
            type: 'SET_NUMBER_OF_DEPENDENTS',
            payload: value,
          });
        }}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['customer-dependents']}
        value={query.numberOfDependents}
      />
    </>
  );
};

PersonalDetails.propTypes = {
  register: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    'customer-title': PropTypes.shape({}),
    'customer-first-name': PropTypes.shape({}),
    'customer-surname': PropTypes.shape({}),
    'customer-telephone': PropTypes.shape({}),
    'customer-email': PropTypes.shape({}),
    'customer-marital': PropTypes.shape({}),
    'customer-dependents': PropTypes.shape({}),
  }).isRequired,
  verifyQuery: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

export default PersonalDetails;
