import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { isValidNumber } from 'libphonenumber-js';

// Component Imports
import InputField from '../Forms/InputField';

// Asset Imports
import AmazonLogo from '../../../assets/images/amazon_logo.png';

const RecommendFriendForm = ({ postURL, channelID }) => {
  const [state, setState] = useState({
    yourName: '',
    yourEmail: '',
    yourPhoneNumber: '',
    theirName: '',
    theirEmail: '',
    theirPhoneNumber: '',
    authenticity_token: '',
    disabled: false,
  });

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);

  const handleFormSubmission = () => {
    if (state.disabled) {
      return;
    }

    setState({
      ...state,
      disabled: true,
      authenticity_token: document.querySelector('[name=csrf-token]').content,
    });
    formRef.current.submit();
  };

  return (
    <form
      className="c-form-simple"
      onSubmit={handleSubmit(handleFormSubmission)}
      noValidate
      ref={formRef}
      action={postURL}
      method="post"
    >
      <input type="hidden" name="authenticity_token" value={state.authenticity_token} />
      <input type="hidden" name="channel_id" value={channelID} />

      <h2 className="c-intro__sub-heading">Your details</h2>

      <InputField
        labelText="Your name"
        id="your_name"
        type="text"
        onChange={(event) => setState({ ...state, yourName: event.target.value })}
        ref={register({ required: true })}
        hasError={!!errors.your_name}
        value={state.yourName}
        hiddenLabel={false}
      />

      <InputField
        type="email"
        labelText="Your email address"
        id="your_email"
        onChange={(event) => setState({ ...state, yourEmail: event.target.value })}
        ref={register({
          required: true,
          pattern:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        })}
        hasError={!!errors.your_email}
        value={state.yourEmail}
        hiddenLabel={false}
      />

      <InputField
        type="tel"
        labelText="Your phone number"
        id="your_phone_number"
        onChange={(event) => setState({ ...state, yourPhoneNumber: event.target.value })}
        ref={register({
          required: true,
          validate: (value) => isValidNumber(value, 'GB'),
        })}
        hasError={!!errors.your_phone_number}
        value={state.yourPhoneNumber}
        hiddenLabel={false}
      />

      <h2 className="c-intro__sub-heading">Friend&apos;s Details</h2>

      <InputField
        labelText="Friend's name"
        id="their_name"
        type="text"
        onChange={(event) => setState({ ...state, theirName: event.target.value })}
        ref={register({ required: true })}
        hasError={!!errors.their_name}
        value={state.theirName}
        hiddenLabel={false}
      />

      <InputField
        type="email"
        labelText="Friend's email address"
        id="their_email"
        onChange={(event) => setState({ ...state, theirEmail: event.target.value })}
        ref={register({
          required: true,
          pattern:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        })}
        hasError={!!errors.their_email}
        value={state.theirEmail}
        hiddenLabel={false}
      />

      <InputField
        type="tel"
        labelText="Friend's phone number"
        id="their_phone_number"
        onChange={(event) => setState({ ...state, theirPhoneNumber: event.target.value })}
        ref={register({
          required: true,
          validate: (value) => isValidNumber(value, 'GB'),
        })}
        hasError={!!errors.their_phone_number}
        value={state.theirPhoneNumber}
        hiddenLabel={false}
      />

      <div className="b-form-group">
        <button className="b-btn b-btn-green b-btn-block" type="submit" disabled={state.disabled}>
          {state.disabled ? 'Sending...' : 'Submit'}
        </button>
      </div>

      <div className="g-form__footer">
        <p className="g-form__asterix">
          *Amazon.co.uk is not a sponsor of this promotion. Amazon.co.uk Gift Cars (&quot;GCs&quot;) may be redeemed on
          the Amazon.co.uk website towards the purchase of eligible products available on www.amazon.co.uk. GCs cannot
          be reloaded, resold, transferred for value, redeemed for cash or applied to any other account. Amazon.co.uk is
          not responsible if a GC is lost, stolen, destroyed or used without permission. See www.amazon.co.uk/gc-legal
          for complete terms and conditions. GCs are issued by Amazon EU S.a.r.l. All Amazon (reg), TM &amp; C are IP of
          Amazon.com. Inc or its affiliates.
        </p>

        <div className="g-form__img">
          <img src={AmazonLogo} alt="amazon logo" />
        </div>
      </div>
    </form>
  );
};

RecommendFriendForm.propTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
};

export default RecommendFriendForm;
