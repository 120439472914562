import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';

// Component Imports
import InputField from '../Forms/InputField';

// Asset Imports
import CarLogo from '../../../assets/images/sign-up-bar-car.png';
import VanLogo from '../../../assets/images/sign-up-bar-van.png';

const SignUp = ({ postURL, channelID }) => {
  const [state, setState] = useState({
    fullName: '',
    email: '',
    authenticity_token: '',
    isFixed: false,
    disabled: false,
  });

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);

  const handleFormSubmission = () => {
    if (state.disabled) {
      return;
    }

    setState({ ...state, disabled: true, authenticity_token: document.querySelector('[name=csrf-token]').content });
    Cookies.set('hide_sign_up', 'true');
    formRef.current.submit();
  };

  const handleCookies = () => {
    setTimeout(() => {
      if (Cookies.get('hide_sign_up') === 'false') {
        setState({ ...state, isFixed: true });
      } else {
        document.body.classList.add('has-no-bar');
      }
    }, 2000);
  };

  const handleClose = (event) => {
    event.preventDefault();

    Cookies.set('hide_sign_up', 'true');
    setState({ ...state, isFixed: false });
  };

  useEffect(() => {
    handleCookies();
  }, []);

  return (
    <section className={`g-sign-up-bar${state.isFixed ? ' is-fixed' : ''}`}>
      <div className="l-container-mark">
        <div className="row justify-content-between">
          <div className="g-sign-up-bar__primary">
            <div className="g-sign-up-bar__icon">
              <img src={channelID === 'van-leasing' ? VanLogo : CarLogo} alt="" />
            </div>

            <header className="g-sign-up-bar__header">
              <h3>
                Get our latest deals
                <br />
                straight to your inbox
              </h3>
            </header>

            <form
              className="g-sign-up-bar__form bootstrap-form"
              onSubmit={handleSubmit(handleFormSubmission)}
              noValidate
              ref={formRef}
              action={postURL}
              method="post"
            >
              <input type="hidden" name="authenticity_token" value={state.authenticity_token} />
              <input type="hidden" name="channel_id" value={channelID} />

              <div className="b-form-row">
                <InputField
                  labelText="Full name"
                  placeholder="Full name"
                  id="full_name"
                  type="text"
                  onChange={(event) => setState({ ...state, fullName: event.target.value })}
                  ref={register({ required: true })}
                  hasError={!!errors.full_name}
                  value={state.fullName}
                />

                <InputField
                  type="email"
                  labelText="Email"
                  id="email"
                  placeholder="Email address"
                  onChange={(event) => setState({ ...state, email: event.target.value })}
                  ref={register({
                    required: true,
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  hasError={!!errors.email}
                  value={state.email}
                />

                <div className="b-form-group">
                  <button type="submit" disabled={state.disabled}>
                    {state.disabled ? 'Sending...' : 'Sign up'}
                  </button>
                </div>
              </div>
            </form>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="g-sign-up-bar__trigger"
              role="button"
              data-path="/newsletter-signup"
              data-action="modal#load"
              data-target="newsletter-signup"
            >
              Get Latest Deals
            </a>
            <span
              className="b-info b-info--gold"
              data-toggle="tooltip"
              data-custom-class="tooltip--white"
              data-placement="top"
              title=""
              data-original-title="Please refer to our privacy policy page for more information."
            />
          </div>
          <div className="g-sign-up-bar__secondary">
            {channelID !== 'van-leasing' && (
              <>
                <h3 className="g-sign-up-bar__title">
                  Find your perfect car
                  <span
                    className="b-info b-info--gold"
                    data-toggle="tooltip"
                    data-custom-class="tooltip--white"
                    data-placement="top"
                    title=""
                    data-original-title="With our unique Find My Perfect Car you can find your perfect car in seconds!"
                  />
                </h3>
                <a href="/find-your-perfect-deal" className="g-sign-up-bar__forward g-sign-up-bar__forward--desktop">
                  Show me how
                </a>
                <a href="/find-your-perfect-deal" className="g-sign-up-bar__forward g-sign-up-bar__forward--mobile">
                  Find Perfect Car
                </a>
              </>
            )}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="g-sign-up-bar__close" href="#" onClick={handleClose}>
              Close
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

SignUp.propTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
};

export default SignUp;
