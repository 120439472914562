import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { isValidNumber } from 'libphonenumber-js';

// Component Imports
import FormField from '../Forms/FormField';
import OptOut from '../Forms/OptOut';
import Recaptcha from '../Recaptcha/Recaptcha';

import { pushData } from '../../ga/form_ga_data';

const RequestCallback = ({ postURL, channelID, recaptcha }) => {
  const [state, setState] = useState({
    fullName: '',
    time: '',
    email: '',
    telephone: '',
    comments: '',
    completeRecaptcha: false,
    authenticity_token: '',
    disabled: false,
  });

  const { register, handleSubmit, errors, getValues } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);
  const recaptchaRef = useRef(null);

  const handleFormSubmission = () => {
    if (recaptcha) {
      if (state.disabled) {
        return;
      }

      if (recaptchaRef.current.value !== '') {
        setState({
          ...state,
          disabled: true,
          authenticity_token: document.querySelector('[name=csrf-token]').content,
        });
        formRef.current.submit();
      } else {
        setState({ ...state, completeRecaptcha: true });
      }
    } else {
      setState({
        ...state,
        disabled: true,
        authenticity_token: document.querySelector('[name=csrf-token]').content,
      });
      formRef.current.submit();
    }

    pushData({
      form_name: 'Request Callback',
      signup: !getValues('contact_opt_out'),
      user_data: {
        email: getValues('contact_email'),
        phone_number: getValues('contact_telephone'),
      },
    });
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmission)}
      noValidate
      ref={formRef}
      action={postURL}
      method="post"
      className="c-callback-form"
    >
      <input type="hidden" name="authenticity_token" value={state.authenticity_token} />
      <input type="hidden" name="channel_id" value={channelID} />

      <div className="row">
        <FormField
          labelText="Full name *"
          placeholder="Full name"
          id="contact_name"
          type="text"
          onChange={(event) => setState({ ...state, fullName: event.target.value })}
          ref={register({ required: true })}
          hasError={!!errors.contact_name}
          value={state.fullName}
        />

        <FormField
          labelText="Preferred contact time"
          id="contact_time"
          onChange={(event) => setState({ ...state, time: event.target.value })}
          ref={register({ required: false })}
          hasError={!!errors.contact_time}
          value={state.time}
          variant="select"
          options={[
            { value: '', text: 'Select contact time' },
            { value: 'Any', text: 'Any' },
            { value: 'Morning', text: 'Morning' },
            { value: 'Afternoon', text: 'Afternoon' },
            { value: 'After 6pm', text: 'After 6pm' },
          ]}
        />
      </div>

      <div className="row">
        <FormField
          labelText="Email *"
          placeholder="Email"
          id="contact_email"
          type="email"
          onChange={(event) => setState({ ...state, email: event.target.value })}
          ref={register({
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          hasError={!!errors.contact_email}
          value={state.email}
        />

        <FormField
          labelText="Telephone *"
          placeholder="Telephone"
          id="contact_telephone"
          type="tel"
          onChange={(event) => setState({ ...state, telephone: event.target.value })}
          ref={register({
            required: true,
            validate: (value) => isValidNumber(value, 'GB'),
          })}
          hasError={!!errors.contact_telephone}
          value={state.telephone}
        />
      </div>

      <div className="row">
        <FormField
          labelText="Comments"
          id="contact_comments"
          placeholder="Comments"
          onChange={(event) => setState({ ...state, comments: event.target.value })}
          value={state.comments}
          variant="textarea"
        />

        <div className="b-form-group col-12 col-sm-6">
          {recaptcha && (
            <>
              <Recaptcha ref={recaptchaRef} />

              {state.completeRecaptcha && (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  Please complete reCaptcha
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className="c-callback-form__action form-group col-12">
          <button className="b-btn b-btn-green b-btn-block b-btn-shadowless" type="submit" disabled={state.disabled}>
            {state.disabled ? 'Sending...' : 'Request a callback'}
          </button>
        </div>
      </div>

      <OptOut ref={register({ required: false })} />
    </form>
  );
};

RequestCallback.propTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
  recaptcha: PropTypes.bool,
};

RequestCallback.defaultProps = {
  recaptcha: false,
};

export default RequestCallback;
